import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './styles/CustomScrollbar.css';
import { Provider } from 'react-redux';
import { store } from './store/config';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { HomeScreen, PrivacyPolicyScreen } from './screens';


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomeScreen />} />
      <Route path="/about" element={<div>Hello about!</div>} />
      <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />      
      <Route path="*" element={<div>Not found!</div>} />            
    </>
  )
);

function AppProvider(){
  
  return (
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>    
  )
}

function AppRoot(){

  return (
    <React.StrictMode>
      <AppProvider />
    </React.StrictMode>    
  )  
}

export default AppRoot;

// function App() {
//   const [token, setToken] = React.useState('');
//   return (
//       <div className='w-full'>
//         <ToastContainer/>
//         <Navbar setToken={setToken} Token={token}/>
//         <Slider/>
//         <Hero Token={token} />
//         <Footer/>
//         {/* <Analytics /> */}
//         {/* <Newsletter /> */}
//         {/* <Cards /> */}
//       </div>
//   );
// }

// export default App;
