import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
// import { store } from './store/config';
// import { Provider } from 'react-redux';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//     // <Provider store={store}>
//       <App />
//     // </Provider>
// );


const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<App />);
} else {
  console.error("Root element not found");
}