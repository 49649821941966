// import { XStack, YStack, Text } from "tamagui";

export function PrivacyPolicyScreen(){
  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ fontWeight: 'bold', fontSize: '20px', textAlign: 'center' }}>Privacy Policy</h1>
      <p>
        The websites (
        <a href="https://www.pitrapaksha.com/" target="_blank" rel="noopener noreferrer">
          https://www.pitrapaksha.com/
        </a>, and{' '}
        <a href="https://www.ayanshala.com/" target="_blank" rel="noopener noreferrer">
          https://www.ayanshala.com/
        </a>
        ) and the mobile application on Android and iOS Platforms 'Panda Seva' (together "Platform") 
        are owned and operated by Ayanshala Technologies Private Limited, a private company 
        incorporated in India under the Companies Act, 2013 and having its registered office at Tower-2 
        Flat NU-G6 Bangalore, Bangalore, Karnataka, India - 560035 and includes any of our affiliates, 
        associates, assignees or successors-in-interest as determined by us at our sole discretion and 
        without requiring any prior notice or intimation to you ("Company", "we", "us" or "our"). The 
        Platform aims to digitize religious experiences for its users, provide religious tourism services 
        and other related services ("Services").
      </p>
      <p style={{ marginTop: '16px' }}> This Privacy Policy ("Privacy Policy") sets out the privacy practices of the Company with respect to the entire content of the Platform. </p> 
      <p style={{ marginTop: '16px' }}> This Privacy Policy is an electronic record as per the Information Technology Act, 2000 (as amended / re-enacted) and rules thereunder ("IT Act") and is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics code) Rules, 2021, which mandates the publishing of rules and regulations, privacy policy and terms and conditions for access or usage of any application or website. This electronic record is generated by a computer system and does not require any physical or digital signature. We request you to go through this Privacy Policy ( <a href="https://www.pitrapaksha.com/privacy-policy/" target="_blank" rel="noopener noreferrer"> https://www.pitrapaksha.com/privacy-policy/ </a> ) carefully before you decide to access this Platform and avail the Services. </p>
      <p style={{ marginTop: '16px' }}> For the purposes of this Privacy Policy, the words "us", "we", and "our" refer to the Company and all references to "you", "your" or "user", as applicable means the person who accesses, uses and/or participates in the Platform in any manner or capacity. </p> 
      <p style={{ marginTop: '16px' }}> The protection and security of your personal information is our top priority and we have taken all necessary and reasonable measures to protect the confidentiality of the user information and its transmission through the internet. </p>
      <p style={{ marginTop: '16px', fontWeight: 'bold' }}> By using our Services and the Platform or by otherwise giving us your information, you agree to the terms of this Privacy Policy. You also expressly consent to our use and disclosure of your Personal Information (as defined below) in the manner prescribed under this Privacy Policy and further signify your agreement to this Privacy Policy and the Terms of Use. If you do not agree to this Privacy Policy, do not subscribe to the Services, use the Platform or give us any of your information in any manner whatsoever. </p>

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>1. COLLECTION OF INFORMATION</h2>

      <h3>1.1 Information We May Collect</h3>
      <p>
        We may collect and process information from you, through your use of the Platform, or which is provided to one of 
        our partners or through any engagement with us. We may collect and process personal information provided by you, 
        including but not limited to:
      </p>
      <ul>
        <li>Information that you voluntarily provide, including but not limited to any information that identifies or can be used to identify, contact or locate the user such as name, phone number, gender, photograph, date of birth, time of birth and place of birth.</li>
        <li>Any data that is automatically captured by the Platform such as your mobile phone operating system, every computer / mobile device connected to the internet is given a domain name and a set of numbers (IP address). When you request a page from any page within the Platform, our web servers automatically recognize your domain name and IP address to help us identify your location. The domain name and IP address reveal nothing personal about you other than the IP address from which you have accessed the Platform;</li>
        <li>Contacts List: We access the contacts list on your mobile device. We always ask for your consent before accessing your contacts list and you have the option to deny us the access to your contacts list.</li>
      </ul>
      <p>
        Hereinafter, collectively referred to as "Personal Information".
      </p>

      <h3>1.2 Payments</h3>
      <p>
        It is clarified that in the event you make any payments through the Platform, we will not store any payment or card-related information 
        which you may provide in the course of making such payments, such as card number, account number, validity date, expiry date or CVV number.
      </p>

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>2. USE OF INFORMATION COLLECTED</h2>
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>2.1 Use of Information for Services</h3>
      <p>
        The primary goal of the Company in collecting the information is to provide you a platform for the purpose of providing the Services. The Company may use 
        the Personal Information provided by you in the following ways:
      </p>      
      <ul>
        <li>To help provide you the Services;</li>
        <li>To observe, improve and administer the quality of Service;</li>
        <li>To analyze how the Platform is used, diagnose technical problems;</li>
        <li>To remember the basic information provided by you for effective access;</li>
        <li>To confirm your identity in order to determine your eligibility to use the Platform and avail the Services;</li>
        <li>To notify you about any changes to the Platform;</li>
        <li>To enable the Company to comply with its legal and regulatory obligations;</li>
        <li>For the purpose of sending administrative notices and Service-related alerts and similar communication, as detailed under this Privacy Policy, with a view to optimizing the efficiency of the Platform;</li>
        <li>Doing market research, troubleshooting, protection against error, project planning, fraud and other criminal activity; and</li>
        <li>To enforce the Company’s Terms of Use.</li>
        <li>To connect you with other Platform users through various features of the Platform;</li>
        <li>In accordance with TRAI regulations, we may reach out to users registered on the National Do Not Call (DND) registry through calls and SMS for essential communications related to our services.</li>
      </ul>

      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>2.2 Sale od Assets , Merger , Aquisition, Bankruptcy</h3>
      <p>
        Information collected from you may be transferred to a third party as a result of a sale or acquisition, merger or bankruptcy involving the Company. Registered Office Address: Tower-2 Flat NU-G6 Bangalore , Bangalore, Karnataka, India - 560035
      </p>      

      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>2.3 Cookies</h3>
      <p>
        Cookies are small portions of information saved by your browser onto your computer / mobile. Cookies are used to record various aspects of your visit and assist the Company to provide you with uninterrupted service.
        We may use information collected from our Cookies to identify user behavior and to serve content and offers based on your profile, in order to personalize your experience and in order to enhance the convenience of the users of our Platform.
        The user acknowledges and agrees that third party service providers may use Cookies or similar technologies to collect information about the user’s pattern of availing the Services, in order to inform, optimize, and provide advertisements based on the user’s visits on the Platform and general browsing pattern and report how third-party service providers advertisement impressions, other uses of advertisement services, and interactions with these impressions and services are in relation to the user’s visits on such third party’s website.
        We neither have access to, nor do the Privacy Policy or Terms of Use govern the use of Cookies or other tracking technologies that may be placed by third party service providers on the Platform. These parties may permit the user to opt out of tailored advertisement at any time, however, to the extent advertising technology is integrated into the Services, the user may still receive advertisements and related updates even if they choose to opt-out of tailored advertising. We assume no responsibility or liability whatsoever for the user’s receipt or use of such tailored advertisements.
      </p>            

      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>2.3 Cookies</h3>
      <p>
        Cookies are small portions of information saved by your browser onto your computer / mobile. Cookies are used to record various aspects of your visit and assist the Company to provide you with uninterrupted service.
        We may use information collected from our Cookies to identify user behavior and to serve content and offers based on your profile, in order to personalize your experience and in order to enhance the convenience of the users of our Platform.
        The user acknowledges and agrees that third party service providers may use Cookies or similar technologies to collect information about the user’s pattern of availing the Services, in order to inform, optimize, and provide advertisements based on the user’s visits on the Platform and general browsing pattern and report how third-party service providers advertisement impressions, other uses of advertisement services, and interactions with these impressions and services are in relation to the user’s visits on such third party’s website.
        We neither have access to, nor do the Privacy Policy or Terms of Use govern the use of Cookies or other tracking technologies that may be placed by third party service providers on the Platform. These parties may permit the user to opt out of tailored advertisement at any time, however, to the extent advertising technology is integrated into the Services, the user may still receive advertisements and related updates even if they choose to opt-out of tailored advertising. We assume no responsibility or liability whatsoever for the user’s receipt or use of such tailored advertisements.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>3. SHARING OF INFORMATION</h2>
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>3.1 Sharing</h3>
      <p>
        Other than as mentioned elsewhere in this Privacy Policy, the Company may share aggregated demographic information with the Company’s partners or affiliates. This is not linked to any Personal Information that can identify an individual person. The Company will not be liable for transfer of any personal identification information resulting from loss or distribution of data, the delineation or corruption of media storage, power failures, natural phenomena, riots, act(s) of vandalism, sabotage, terrorism or any other event beyond Company’s control. Further, the Company’s Privacy Policy does not cover the use of Cookies by its partners and affiliates since the Company does not have access or control over such Cookies.
      </p>                  
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>3.2 Consulting</h3>
      <p>
        The Company may partner with another party to provide certain specific services if required. When you sign-up for such services, the Company may share such information, including without limitation, names, or other Personal Information that is necessary for the third party to provide these Services. The Company’s contractual arrangements with such third parties restrict these parties from using personally identifiable information except for the explicit purpose of assisting in the provision of these Services.
      </p>                  
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>3.3 Targeted Advertising</h3>
      <p>
        You expressly acknowledge and agree that we may also share or transfer information in relation to your browsing history, cache, internet protocol address and domain name to third-party service providers, for the limited purpose of allowing or permitting such third-party service providers, including Google, and other social media websites to Registered Office Address: Tower-2 Flat NU-G6 Bangalore , Bangalore, Karnataka, India - 560035 display advertisements and notifications on websites across the Internet, based on the information that is collected by us, to optimize and display advertisements based on your past preferences and visits on the Platform as part of its Services. The Company does not allow any unauthorized persons or organization to use any information that the Company may collect from you through this Platform.
      </p>                  
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>3.4 Regulatory Disclosures</h3>
      <p>
        We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. Thus, we may access, use, store, transfer and disclose your information (including Personal Information), including disclosure to third parties such as government or law enforcement officials or private parties as we reasonably determine is necessary and appropriate: (i) to satisfy any applicable law, regulation, governmental requests or legal process; (ii) to protect the safety, rights, property or security of the Company, our Services, the Platform or any third party; (iii) to protect the safety of the public for any reason; (iv) to detect, prevent or otherwise address fraud, security or technical issues; and /or (v) to prevent or stop any activity we consider to be, or to pose a risk of being, an illegal, unethical, or legally actionable activity. Such disclosures may be carried out without notice to you.
      </p>                  
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>3.5 Referrals</h3>
      <p>
        When you e-mail/message a service available on the Platform to a friend, you and your friend's names and e-mail addresses/numbers are requested. This ensures that your friend will know that you have requested that the Company send them an e-mail/message. Your email and your friends’ e-mail addresses will only be used for this purpose unless permission is otherwise granted.
      </p>                  
      <h3 style={{ marginTop: '16px', fontWeight: 'bold'}}>3.6 Link to Third Party Websites</h3>
      <p>
        This Platform may contain links which may lead you to other Websites. Please note that once you leave the Company’s Platform you will be subjected to the privacy policy of such other websites. The linked websites are not necessarily under the control of the Company. Please be aware that the Company is not responsible for the privacy practices of such other sites. The Company encourages you to read the privacy policies of each and every website that collects Personal Information. If you decide to access any of the third-party sites linked to the Platform, you do this entirely at your own risk. Any links to any partner of the Company should be the responsibility of the linking party, and the Company will not be responsible for notification of any change in name or location of any information on the Platform.
      </p>                  
      <p>
        3.7 In the event of an error in the app we collect data and information (through third parties) on your phone called Log Data. This Log Data may include information such as your device internet protocol address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other such related statistics.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>4. SECURITY OF INFORMATION</h2>
      <p>
        4.1 The Company has put in place necessary security practices and procedures to safeguard and secure such Personal Information. The Company only processes Personal Information in a way that is compatible with and relevant for Registered Office Address: Tower-2 Flat NU-G6 Bangalore , Bangalore, Karnataka, India - 560035 the purpose for which it was collected or authorized by the user. The Platform allows users access to their Personal Information and allows them to correct, amend or delete inaccurate information.
      </p>                  
      <p>
        4.2 The Company uses commercially reasonable precautions to preserve the integrity and security of your information against loss, theft, unauthorised access, disclosure, reproduction, use or amendment.
      </p>                  
      <p>
        4.3 The information that is collected from you may be transferred to, stored and processed at any destination within and / or outside India. By submitting information on the Platform, you agree to this transfer, storing and / or processing. The Company will take such steps as it considers reasonably necessary to ensure that your information is treated securely and in accordance with this Privacy Policy.
      </p>                  
      <p>
        4.4 In using the Platform, you accept the inherent security implications of data transmission over the internet. Therefore, the use of the Platform will be at your own risk and the Company assumes no liability for any disclosure of information due to errors in transmission, unauthorised third-party access or other acts of third parties, or acts or omissions beyond its reasonable control and you agree not to hold the Company responsible for any breach of security.
      </p>                  
      <p>
        4.5 In the event the Company becomes aware of any breach of the security of your information, it will promptly notify you and take appropriate action to the best of its ability to remedy such a breach.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>5. EXCLUSION</h2>
      <p>
        5.1 This Privacy Policy does not apply to any information other than information collected by the Company through the Platform including such information collected in accordance with the clause on “Collection of Information” above. This Privacy Policy will not apply to any unsolicited information provided by you through this Platform or through any other means. This includes, but is not limited to, information posted on any public areas of the Platform. All such unsolicited information shall be deemed to be non-confidential and the Company will be free to use, disclose such unsolicited information without limitation.
      </p>                  
      <p>
        5.2 The Company also protects your Personal Information off-line other than as specifically mentioned in this Privacy Policy. Access to your Personal Information is limited to employees, agents or partners and third parties, who the Company reasonably believes will need that information to enable the Company to provide Services to you. However, the Company is not responsible for the confidentiality, security or distribution of your own Personal Information by our partners and third parties outside the scope of our agreement with such partners and third parties.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>6. DATA RETENTION</h2>
      <p>
        The Company shall not retain Personal Information longer than is necessary to fulfil the purposes for which it was collected and as permitted by applicable law. If you wish to cancel your account or request that the Company no longer use your information to provide you Services, contact us through email at info@ayanshala.com. Even after your account is terminated, the Company may retain your Personal Information as needed to comply with our legal and regulatory obligations, resolve disputes, conclude any activities related to cancellation of an account, investigate or prevent fraud and other inappropriate activity, to enforce our agreements, and for other business reason.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>7. RIGHT TO WITHDRAW CONSENT</h2>
      <p>
        Registered Office Address: Tower-2 Flat NU-G6 Bangalore , Bangalore, Karnataka, India - 560035 The consent that you provide for the collection, use and disclosure of your Personal Information will remain valid until such time it is withdrawn by you in writing. If you withdraw your consent, the Company will stop processing the relevant Personal Information except to the extent that the Company has other grounds for processing such Personal Information under applicable laws. The Company will respond to your request within a reasonable timeframe. You may withdraw your consent at any time by contacting the Company at info@ayanshala.com.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>8. RIGHT TO ACCOUNT DELETION</h2>
      <p>
        You may request deletion of your account at any time by submitting your request via email at info@ayanshala.com. Following an account deletion request, we will delete your account and Personal Information, unless it is required to be retained due to legal or regulatory requirements. Please note that we may take time to process deletion requests, and after the account is deleted, we will not use your Personal Information for any processing unless required by us to comply with our legal obligations in accordance with this Policy.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>9. RIGHT TO CORRECTION</h2>
      <p>
        You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. The Company relies on the users to disclose to it all relevant and accurate information and to inform the Company of any changes. If you wish to make a request to correct or update any of your personal data which we hold about you, you may submit your request via email at info@ayanshala.com.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>10. NOTIFICATION OF CHANGES</h2>
      <p>
        From time to time, the Company may update this Privacy Policy to reflect changes to its information practices. Any changes will be effective immediately upon the posting of the revised Privacy Policy. If the Company makes any material changes, it will notify you by means of a notice on the Services prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>11. GRIEVANCE OFFICER</h2>
      <p>
        In the event that you wish to raise a query or complaint with us, please contact our Grievance Officer (contact details set out below) who shall acknowledge your complaint within 24 (twenty four) hours from the time of receipt of such complaint. Kindly note that once your complaint is received, we shall use our best efforts to redress it within a period of 15 (fifteen) days from the date of receipt of such complaint: Name: Sunil Kumar Sinha Contact Number: +91 8951 262703 Email ID: info@ayanshala.com.
      </p>                  

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline' }}>12. ADDRESS FOR PRIVACY QUESTIONS</h2>
      <p>
        Should you have questions about this Privacy Policy or Company’s information collection, use and disclosure practices, you may contact us at info@ayanshala.com. We will use reasonable efforts to respond promptly to requests, questions or concerns you may have regarding our use of Personal Information about you. Except where Registered Office Address: Tower-2 Flat NU-G6 Bangalore , Bangalore, Karnataka, India - 560035 required by law, the Company cannot ensure a response to questions or comments regarding topics unrelated to this policy or Company’s privacy practices.
      </p>              

      <h2 style={{ marginTop: '16px', fontWeight: 'bold', textDecoration: 'underline', textAlign: 'center' }}>YOU HAVE READ THIS PRIVACY POLICY AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE.</h2>
      <p>
        Last updated on 24/10/2024
      </p>              
      <p>
        Registered Office Address: Tower-2 Flat NU-G6 Bangalore , Bangalore, Karnataka, India - 560035.
      </p>                    

    </div>
  );
}


// import React from 'react';

// const PrivacyPolicy = () => {
//   return (
//     <div>
//       <h1>Privacy Policy</h1>
//       <p>
//         The websites (<a href="https://www.srimandir.com/">https://www.srimandir.com/</a>, 
//         <a href="https://www.srimandir.in/">https://www.srimandir.in/</a>, and 
//         <a href="https://www.appsforbharat.com/">https://www.appsforbharat.com/</a>) and the mobile application on Android and iOS Platforms 'Sri Mandir' (together "Platform") are owned and operated by FirstPrinciple AppsForBharat Private Limited, a private company incorporated in India under the Companies Act, 2013...
//       </p>
//       <p>
//         Registered Office Address: #435, First Floor, 17th Cross, 19th Main Rd, Above Axis Bank, Sector 4, HSR Layout, Bengaluru, Karnataka - 560102 and includes any of our affiliates, associates, assignees or successors-in-interest as determined by us at our sole discretion and without requiring any prior notice or intimation to you ("Company", "we", "us" or "our").
//       </p>

//       <h2>Collection of Information</h2>
//       <p>1.1 We may collect and process information from you...</p>

//       <h2>Use of Information</h2>
//       <p>2.1 Use of the Information for Services...</p>

//       <h2>Sharing of Information</h2>
//       <p>3.1 Sharing...</p>

//       <h2>Security of Information</h2>
//       <p>4.1 The Company has put in place necessary security practices...</p>

//       <h2>Data Retention</h2>
//       <p>6. The Company shall not retain Personal Information longer...</p>

//       <h2>Right to Withdraw Consent</h2>
//       <p>7. The consent that you provide for the collection...</p>

//       <h2>Right to Account Deletion</h2>
//       <p>8. You may request deletion of your account...</p>

//       <h2>Grievance Officer</h2>
//       <p>11. In the event that you wish to raise a query or complaint...</p>

//       <p><strong>Last updated on 14/03/2023</strong></p>
//       <p>Registered Office Address: #435, First Floor, 17th Cross, 19th Main Rd, Above Axis Bank, Sector 4, HSR Layout, Bengaluru, Karnataka, 560102</p>
//     </div>
//   );
// };

// export default PrivacyPolicy;
