const { createTransform } = require('redux-persist');
const storage = require('redux-persist/lib/storage').default; // Make sure to use `.default`
const { ayanshalaBackendApi } = require('../services/api');
const { persistReducer } = require('redux-persist');
const { default: authSlice } = require('./auth/authSlice'); // Make sure to use `.reducer`

const elevateWhitelistFilter = createTransform(
  (inboundState) => {
    const persistedObject = {};
    const functionPattern = /^(fetchBatches|getCourseAssetAccess)/;
    Object.keys(inboundState).forEach((dynamicKey) => {
      if (functionPattern.test(dynamicKey)) {
        persistedObject[dynamicKey] = inboundState[dynamicKey];
        persistedObject[dynamicKey].status = 'fulfilled';
      }
    });
    return persistedObject;
  },
  (outboundState) => {
    return { ...outboundState };
  },
  { whitelist: ['queries'] }
);

const ayanshalaBackendPersistConfig = {
  key: ayanshalaBackendApi.reducerPath,
  storage: storage,
  transforms: [elevateWhitelistFilter]
};
// const authSlicePersistConfig = {
//   key: 'auth',
//   storage: storage,
// };

export const rootReducer = {
  [ayanshalaBackendApi.reducerPath]: persistReducer(ayanshalaBackendPersistConfig, ayanshalaBackendApi.reducer),
  auth:authSlice, // Make sure `authSliceReducer` is correct
};

export const rootMiddleware = [
  ayanshalaBackendApi.middleware,
];

// module.exports = {
//   rootReducer,
//   rootMiddleware
// };
