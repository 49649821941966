import React from "react";
import Slider from '../../components/Slider';
import Hero from '../../components/Hero';
// import Analytics from '../../components/Analytics';
// import Cards from '../../components/Cards';
// import Newsletter from '../../components/Newsletter';
import Footer from '../../components/Footer';
import { ToastContainer } from 'react-toastify';
import Navbar from '../../components/Navbar';

export function HomeScreen(){
  
  const [token, setToken] = React.useState('');
  
  return (
    <div className='w-full'>
      <ToastContainer/>
      {/* <Navbar setToken={setToken} Token={token}/> */}
      <Navbar />  
      <Slider />
      <Hero token={token} />
      <Footer/>
      {/* <Analytics /> */}
      {/* <Newsletter /> */}
      {/* <Cards />     */}
    </div>
  )
}